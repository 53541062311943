var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "right-board" },
    [
      _c(
        "el-tabs",
        {
          staticClass: "center-tabs",
          model: {
            value: _vm.currentTab,
            callback: function ($$v) {
              _vm.currentTab = $$v
            },
            expression: "currentTab",
          },
        },
        [
          _c("el-tab-pane", { attrs: { label: "组件属性", name: "field" } }),
          _c("el-tab-pane", { attrs: { label: "组件样式", name: "style" } }),
          _c("el-tab-pane", { attrs: { label: "表单属性", name: "form" } }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "field-box" },
        [
          _c(
            "el-scrollbar",
            { staticClass: "right-scrollbar" },
            [
              _c(
                "el-form",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === "field" && _vm.showField,
                      expression: "currentTab==='field' && showField",
                    },
                  ],
                  attrs: {
                    size: "small",
                    "label-width": "90px",
                    labelPosition: "left",
                  },
                },
                [
                  _vm.activeData.__config__
                    ? [
                        _vm.$store.getters.hasTable
                          ? [
                              _vm.activeData.__config__.jnpfKey === "table"
                                ? [
                                    (_vm.activeData.__vModel__ !== undefined &&
                                      !_vm.noVModelList.includes(
                                        _vm.activeData.__config__.jnpfKey
                                      )) ||
                                    _vm.activeData.__config__.isStorage == 2
                                      ? _c(
                                          "el-form-item",
                                          { attrs: { label: "控件字段" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder: "请输入数据库字段",
                                                disabled: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.activeData.__vModel__,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.activeData,
                                                    "__vModel__",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "activeData.__vModel__",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                : [
                                    !_vm.activeData.__config__.isSubTable
                                      ? [
                                          _vm.activeData.__config__.jnpfKey ===
                                            "calculate" ||
                                          _vm.activeData.__config__.jnpfKey ===
                                            "popupAttr" ||
                                          _vm.activeData.__config__.jnpfKey ===
                                            "relationFormAttr"
                                            ? [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "控件类型",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        attrs: {
                                                          placeholder: "请选择",
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.changeStorage,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.activeData
                                                              .__config__
                                                              .isStorage,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.activeData
                                                                .__config__,
                                                              "isStorage",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "activeData.__config__.isStorage",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.storageType,
                                                        function (item, i) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: i,
                                                              attrs: {
                                                                label:
                                                                  item.label,
                                                                value:
                                                                  item.value,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            : _vm._e(),
                                          (_vm.activeData.__vModel__ !==
                                            undefined &&
                                            !_vm.noVModelList.includes(
                                              _vm.activeData.__config__.jnpfKey
                                            )) ||
                                          _vm.activeData.__config__.isStorage ==
                                            2
                                            ? _c(
                                                "el-form-item",
                                                {
                                                  attrs: { label: "数据库表" },
                                                },
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      attrs: {
                                                        placeholder:
                                                          "请选择数据库表",
                                                        filterable: "",
                                                      },
                                                      on: {
                                                        change: _vm.tableChange,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.activeData
                                                            .__config__
                                                            .tableName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.activeData
                                                              .__config__,
                                                            "tableName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "activeData.__config__.tableName",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.allTable,
                                                      function (item) {
                                                        return _c(
                                                          "el-option",
                                                          {
                                                            key: item.table,
                                                            attrs: {
                                                              value: item.table,
                                                              label:
                                                                item.tableName
                                                                  ? item.table +
                                                                    "(" +
                                                                    item.tableName +
                                                                    ")"
                                                                  : item.table,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "custom-option-left",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          " +
                                                                    _vm._s(
                                                                      item.tableName
                                                                        ? item.table +
                                                                            "(" +
                                                                            item.tableName +
                                                                            ")"
                                                                        : item.table
                                                                    ) +
                                                                    "\n                        "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "custom-option-right",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.typeId ==
                                                                      "1"
                                                                      ? "主表"
                                                                      : "从表"
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          (_vm.activeData.__vModel__ !==
                                            undefined &&
                                            !_vm.noVModelList.includes(
                                              _vm.activeData.__config__.jnpfKey
                                            )) ||
                                          _vm.activeData.__config__.isStorage ==
                                            2
                                            ? _c(
                                                "el-form-item",
                                                {
                                                  attrs: { label: "控件字段" },
                                                },
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      attrs: {
                                                        placeholder:
                                                          "请选择数据库字段",
                                                        clearable: "",
                                                        filterable: "",
                                                        "popper-class":
                                                          "field-select-popper",
                                                      },
                                                      on: {
                                                        change: _vm.fieldChange,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.activeData
                                                            .__vModel__,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.activeData,
                                                            "__vModel__",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "activeData.__vModel__",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "el-select-dropdown__empty",
                                                          attrs: {
                                                            slot: "empty",
                                                          },
                                                          slot: "empty",
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "无匹配数据，"
                                                            ),
                                                          ]),
                                                          _c(
                                                            "el-link",
                                                            {
                                                              attrs: {
                                                                type: "primary",
                                                                underline: false,
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.openFieldDialog,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "添加字段\n                        "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm.fieldOptions.length
                                                        ? _c(
                                                            "el-option-group",
                                                            {
                                                              attrs: {
                                                                label: "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-option",
                                                                {
                                                                  attrs: {
                                                                    disabled:
                                                                      "",
                                                                    value: "-",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-link",
                                                                    {
                                                                      attrs: {
                                                                        type: "primary",
                                                                        underline: false,
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          _vm.openFieldDialog,
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "添加字段\n                          "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _c(
                                                        "el-option-group",
                                                        {
                                                          attrs: { label: "" },
                                                        },
                                                        _vm._l(
                                                          _vm.fieldOptions,
                                                          function (item) {
                                                            return _c(
                                                              "el-option",
                                                              {
                                                                key: item.filedName,
                                                                attrs: {
                                                                  value:
                                                                    item.filedName,
                                                                  label:
                                                                    item.comment
                                                                      ? item.filedName +
                                                                        "(" +
                                                                        item.comment +
                                                                        ")"
                                                                      : item.filedName,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      : _vm._e(),
                                    _vm.activeData.__config__.isSubTable &&
                                    _vm.subTable.length
                                      ? [
                                          _vm.activeData.__config__.jnpfKey ===
                                            "calculate" ||
                                          _vm.activeData.__config__.jnpfKey ===
                                            "popupAttr" ||
                                          _vm.activeData.__config__.jnpfKey ===
                                            "relationFormAttr"
                                            ? [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "控件类型",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        attrs: {
                                                          placeholder: "请选择",
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.changeStorage,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.activeData
                                                              .__config__
                                                              .isStorage,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.activeData
                                                                .__config__,
                                                              "isStorage",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "activeData.__config__.isStorage",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.storageType,
                                                        function (item, i) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: i,
                                                              attrs: {
                                                                label:
                                                                  item.label,
                                                                value:
                                                                  item.value,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            : _vm._e(),
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "数据库表" } },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    placeholder:
                                                      "请选择数据库表",
                                                    filterable: "",
                                                    disabled: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.activeData.__config__
                                                        .relationTable,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.activeData
                                                          .__config__,
                                                        "relationTable",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "activeData.__config__.relationTable",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.allTable,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.table,
                                                      attrs: {
                                                        value: item.table,
                                                        label: item.tableName
                                                          ? item.table +
                                                            "(" +
                                                            item.tableName +
                                                            ")"
                                                          : item.table,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          (_vm.activeData.__vModel__ !==
                                            undefined &&
                                            !_vm.noVModelList.includes(
                                              _vm.activeData.__config__.jnpfKey
                                            )) ||
                                          _vm.activeData.__config__.isStorage ==
                                            2
                                            ? _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "控件字段11",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      attrs: {
                                                        placeholder:
                                                          "请选择数据库字段",
                                                        clearable: "",
                                                        filterable: "",
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.fieldChange1,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.activeData
                                                            .__vModel__,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.activeData,
                                                            "__vModel__",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "activeData.__vModel__",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "el-select-dropdown__empty",
                                                          attrs: {
                                                            slot: "empty",
                                                          },
                                                          slot: "empty",
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "无匹配数据，"
                                                            ),
                                                          ]),
                                                          _c(
                                                            "el-link",
                                                            {
                                                              attrs: {
                                                                type: "primary",
                                                                underline: false,
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.openFieldDialog,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "添加字段\n                        "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm.getSubTableFiled(
                                                        _vm.activeData
                                                          .__config__
                                                          .relationTable
                                                      ).length
                                                        ? _c(
                                                            "el-option-group",
                                                            {
                                                              attrs: {
                                                                label: "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-option",
                                                                {
                                                                  attrs: {
                                                                    disabled:
                                                                      "",
                                                                    value: "-",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-link",
                                                                    {
                                                                      attrs: {
                                                                        type: "primary",
                                                                        underline: false,
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          _vm.openFieldDialog,
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "添加字段\n                          "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _c(
                                                        "el-option-group",
                                                        {
                                                          attrs: { label: "" },
                                                        },
                                                        _vm._l(
                                                          _vm.getSubTableFiled(
                                                            _vm.activeData
                                                              .__config__
                                                              .relationTable
                                                          ),
                                                          function (item) {
                                                            return _c(
                                                              "el-option",
                                                              {
                                                                key: item.filedName,
                                                                attrs: {
                                                                  value:
                                                                    item.filedName,
                                                                  label:
                                                                    item.comment
                                                                      ? item.filedName +
                                                                        "(" +
                                                                        item.comment +
                                                                        ")"
                                                                      : item.filedName,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      : _vm._e(),
                                  ],
                            ]
                          : [
                              _vm.activeData.__config__.jnpfKey ===
                                "calculate" ||
                              _vm.activeData.__config__.jnpfKey ===
                                "popupAttr" ||
                              _vm.activeData.__config__.jnpfKey ===
                                "relationFormAttr"
                                ? [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "控件类型" } },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: { placeholder: "请选择" },
                                            on: { change: _vm.changeStorage },
                                            model: {
                                              value:
                                                _vm.activeData.__config__
                                                  .isStorage,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.activeData.__config__,
                                                  "isStorage",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "activeData.__config__.isStorage",
                                            },
                                          },
                                          _vm._l(
                                            _vm.storageType,
                                            function (item, i) {
                                              return _c("el-option", {
                                                key: i,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                              (_vm.activeData.__vModel__ !== undefined &&
                                !_vm.noVModelList.includes(
                                  _vm.activeData.__config__.jnpfKey
                                )) ||
                              _vm.activeData.__config__.isStorage == 2
                                ? _c(
                                    "el-form-item",
                                    { attrs: { label: "控件字段" } },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "请输入数据库字段",
                                          disabled:
                                            _vm.activeData.__config__
                                              .jnpfKey === "table",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.inputFieldChange(
                                              $event,
                                              _vm.activeData.__config__.formId,
                                              _vm.activeData.__config__
                                                .parentVModel
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.activeData.__vModel__,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.activeData,
                                              "__vModel__",
                                              $$v
                                            )
                                          },
                                          expression: "activeData.__vModel__",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                        _vm.activeData.__config__.jnpfKey === "comInput"
                          ? _c("SNOWSComInput", {
                              attrs: { "active-data": _vm.activeData },
                            })
                          : _vm._e(),
                        _vm.activeData.__config__.jnpfKey === "textarea"
                          ? _c("SNOWS-textarea", {
                              attrs: { "active-data": _vm.activeData },
                            })
                          : _vm._e(),
                        _vm.activeData.__config__.jnpfKey === "SNOWSText"
                          ? _c("SNOWSText", {
                              attrs: { "active-data": _vm.activeData },
                            })
                          : _vm._e(),
                        _vm.activeData.__config__.jnpfKey === "link"
                          ? _c("SNOWSLink", {
                              attrs: { "active-data": _vm.activeData },
                            })
                          : _vm._e(),
                        _vm.activeData.__config__.jnpfKey === "alert"
                          ? _c("SNOWSAlert", {
                              attrs: { "active-data": _vm.activeData },
                            })
                          : _vm._e(),
                        _vm.activeData.__config__.jnpfKey === "numInput"
                          ? _c("SNOWSNumInput", {
                              attrs: { "active-data": _vm.activeData },
                            })
                          : _vm._e(),
                        _vm.activeData.__config__.jnpfKey === "SNOWSAmount"
                          ? _c("SNOWSAmount", {
                              attrs: { "active-data": _vm.activeData },
                            })
                          : _vm._e(),
                        _vm.activeData.__config__.jnpfKey === "PsdInput"
                          ? _c("SNOWSPsdInput", {
                              attrs: { "active-data": _vm.activeData },
                            })
                          : _vm._e(),
                        _vm.activeData.__config__.jnpfKey === "radio"
                          ? _c("SNOWSRadio", {
                              attrs: {
                                "active-data": _vm.activeData,
                                dictionaryOptions: _vm.dictionaryOptions,
                                dataInterfaceOptions: _vm.dataInterfaceOptions,
                              },
                            })
                          : _vm._e(),
                        _vm.activeData.__config__.jnpfKey === "checkbox"
                          ? _c("SNOWSCheckbox", {
                              attrs: {
                                "active-data": _vm.activeData,
                                dictionaryOptions: _vm.dictionaryOptions,
                                dataInterfaceOptions: _vm.dataInterfaceOptions,
                              },
                            })
                          : _vm._e(),
                        _vm.activeData.__config__.jnpfKey === "select"
                          ? _c("SNOWSSelect", {
                              key: _vm.activeData.__config__.renderKey,
                              attrs: {
                                "active-data": _vm.activeData,
                                dictionaryOptions: _vm.dictionaryOptions,
                                dataInterfaceOptions: _vm.dataInterfaceOptions,
                              },
                            })
                          : _vm._e(),
                        _vm.activeData.__config__.jnpfKey === "cascader"
                          ? _c("SNOWSCascader", {
                              attrs: {
                                "active-data": _vm.activeData,
                                dictionaryOptions: _vm.dictionaryOptions,
                                dataInterfaceOptions: _vm.dataInterfaceOptions,
                              },
                            })
                          : _vm._e(),
                        _vm.activeData.__config__.jnpfKey === "time"
                          ? _c("SNOWSTime", {
                              attrs: { "active-data": _vm.activeData },
                            })
                          : _vm._e(),
                        _vm.activeData.__config__.jnpfKey === "timeRange"
                          ? _c("SNOWSTimeRange", {
                              attrs: { "active-data": _vm.activeData },
                            })
                          : _vm._e(),
                        _vm.activeData.__config__.jnpfKey === "date"
                          ? _c("SNOWSDate", {
                              attrs: { "active-data": _vm.activeData },
                            })
                          : _vm._e(),
                        _vm.activeData.__config__.jnpfKey === "dateRange"
                          ? _c("SNOWSDateRange", {
                              attrs: { "active-data": _vm.activeData },
                            })
                          : _vm._e(),
                        _vm.activeData.__config__.jnpfKey === "colorPicker"
                          ? _c("SNOWSColorPicker", {
                              attrs: { "active-data": _vm.activeData },
                            })
                          : _vm._e(),
                        _vm.activeData.__config__.jnpfKey === "rate"
                          ? _c("SNOWSRate", {
                              attrs: { "active-data": _vm.activeData },
                            })
                          : _vm._e(),
                        _vm.activeData.__config__.jnpfKey === "switch"
                          ? _c("SNOWSSwitch", {
                              attrs: { "active-data": _vm.activeData },
                            })
                          : _vm._e(),
                        _vm.activeData.__config__.jnpfKey === "slider"
                          ? _c("SNOWSSlider", {
                              attrs: { "active-data": _vm.activeData },
                            })
                          : _vm._e(),
                        _vm.activeData.__config__.jnpfKey === "divider"
                          ? _c("SNOWSDivider", {
                              attrs: { "active-data": _vm.activeData },
                            })
                          : _vm._e(),
                        _vm.activeData.__config__.jnpfKey === "uploadFz"
                          ? _c("SNOWSUploadFz", {
                              attrs: { "active-data": _vm.activeData },
                            })
                          : _vm._e(),
                        _vm.activeData.__config__.jnpfKey === "uploadImg"
                          ? _c("SNOWSUploadImg", {
                              attrs: { "active-data": _vm.activeData },
                            })
                          : _vm._e(),
                        _vm.isCommon
                          ? _c("SNOWSComRight", {
                              attrs: { "active-data": _vm.activeData },
                            })
                          : _vm._e(),
                        _vm.activeData.__config__.jnpfKey === "address"
                          ? _c("SNOWSAddress", {
                              attrs: { "active-data": _vm.activeData },
                            })
                          : _vm._e(),
                        _vm.activeData.__config__.jnpfKey === "treeSelect"
                          ? _c("TreeSelect", {
                              attrs: {
                                "active-data": _vm.activeData,
                                dictionaryOptions: _vm.dictionaryOptions,
                                dataInterfaceOptions: _vm.dataInterfaceOptions,
                              },
                            })
                          : _vm._e(),
                        _vm.activeData.__config__.jnpfKey === "groupTitle"
                          ? _c("GroupTitle", {
                              attrs: { "active-data": _vm.activeData },
                            })
                          : _vm._e(),
                        _vm.activeData.__config__.jnpfKey === "relationForm"
                          ? _c(
                              "RelationForm",
                              _vm._g(
                                {
                                  key: _vm.activeData.__config__.renderKey,
                                  attrs: { "active-data": _vm.activeData },
                                },
                                _vm.$listeners
                              )
                            )
                          : _vm._e(),
                        _vm.activeData.__config__.jnpfKey === "relationFormAttr"
                          ? _c("RelationFormAttr", {
                              ref: "relationFormAttr",
                              attrs: { "active-data": _vm.activeData },
                            })
                          : _vm._e(),
                        _vm.activeData.__config__.jnpfKey === "calculate"
                          ? _c("SNOWSCalculate", {
                              ref: "calculate",
                              attrs: { "active-data": _vm.activeData },
                            })
                          : _vm._e(),
                        _vm.activeData.__config__.jnpfKey === "popupSelect" ||
                        _vm.activeData.__config__.jnpfKey === "popupTableSelect"
                          ? _c("PopupSelect", {
                              attrs: { "active-data": _vm.activeData },
                            })
                          : _vm._e(),
                        _vm.activeData.__config__.jnpfKey === "popupAttr"
                          ? _c("PopupAttr", {
                              ref: "popupAttr",
                              attrs: { "active-data": _vm.activeData },
                            })
                          : _vm._e(),
                        _vm.activeData.__config__.jnpfKey === "tab"
                          ? _c("Tab", {
                              attrs: { "active-data": _vm.activeData },
                            })
                          : _vm._e(),
                        _vm.activeData.__config__.jnpfKey === "collapse"
                          ? _c("Collapse", {
                              attrs: { "active-data": _vm.activeData },
                            })
                          : _vm._e(),
                        _vm.activeData.__config__.jnpfKey === "barcode"
                          ? [
                              _c(
                                "el-form-item",
                                { attrs: { label: "控件标题" } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入控件标题" },
                                    model: {
                                      value: _vm.activeData.__config__.label,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.activeData.__config__,
                                          "label",
                                          $$v
                                        )
                                      },
                                      expression: "activeData.__config__.label",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "编码格式" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择" },
                                      model: {
                                        value: _vm.activeData.format,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.activeData,
                                            "format",
                                            $$v
                                          )
                                        },
                                        expression: "activeData.format",
                                      },
                                    },
                                    _vm._l(
                                      _vm.barcodeFormatOptions,
                                      function (item, i) {
                                        return _c("el-option", {
                                          key: i,
                                          attrs: {
                                            label: item.label,
                                            value: item.value,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "条码颜色" } },
                                [
                                  _c("el-color-picker", {
                                    model: {
                                      value: _vm.activeData.lineColor,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.activeData,
                                          "lineColor",
                                          $$v
                                        )
                                      },
                                      expression: "activeData.lineColor",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "宽度" } },
                                [
                                  _c("el-input-number", {
                                    attrs: {
                                      placeholder: "宽度",
                                      min: 0,
                                      precision: 0,
                                      "controls-position": "right",
                                    },
                                    model: {
                                      value: _vm.activeData.width,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.activeData, "width", $$v)
                                      },
                                      expression: "activeData.width",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "高度" } },
                                [
                                  _c("el-input-number", {
                                    attrs: {
                                      placeholder: "高度",
                                      min: 0,
                                      precision: 0,
                                      "controls-position": "right",
                                    },
                                    model: {
                                      value: _vm.activeData.height,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.activeData, "height", $$v)
                                      },
                                      expression: "activeData.height",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "默认值" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择" },
                                      model: {
                                        value: _vm.activeData.dataType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.activeData,
                                            "dataType",
                                            $$v
                                          )
                                        },
                                        expression: "activeData.dataType",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: {
                                          label: "固定值",
                                          value: "static",
                                        },
                                      }),
                                      _c("el-option", {
                                        attrs: {
                                          label: "组件联动",
                                          value: "relation",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.activeData.dataType === "static"
                                ? _c(
                                    "el-form-item",
                                    { attrs: { label: "固定值" } },
                                    [
                                      _c("el-input", {
                                        attrs: { placeholder: "请输入固定值" },
                                        on: { change: _vm.onBarcodeTextChange },
                                        model: {
                                          value: _vm.activeData.staticText,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.activeData,
                                              "staticText",
                                              $$v
                                            )
                                          },
                                          expression: "activeData.staticText",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.activeData.dataType === "relation"
                                ? _c(
                                    "el-form-item",
                                    { attrs: { label: "选择组件" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: "请选择",
                                            clearable: "",
                                            filterable: "",
                                          },
                                          model: {
                                            value: _vm.activeData.relationField,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.activeData,
                                                "relationField",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "activeData.relationField",
                                          },
                                        },
                                        _vm._l(
                                          _vm.drawingOptions,
                                          function (item, i) {
                                            return _c("el-option", {
                                              key: i,
                                              attrs: {
                                                label: item.__config__.label,
                                                value: item.__vModel__,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          : _vm._e(),
                        _vm.activeData.__config__.jnpfKey === "qrcode"
                          ? [
                              _c(
                                "el-form-item",
                                { attrs: { label: "控件标题" } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入控件标题" },
                                    model: {
                                      value: _vm.activeData.__config__.label,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.activeData.__config__,
                                          "label",
                                          $$v
                                        )
                                      },
                                      expression: "activeData.__config__.label",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "实点颜色" } },
                                [
                                  _c("el-color-picker", {
                                    model: {
                                      value: _vm.activeData.colorDark,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.activeData,
                                          "colorDark",
                                          $$v
                                        )
                                      },
                                      expression: "activeData.colorDark",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "尺寸" } },
                                [
                                  _c("el-input-number", {
                                    attrs: {
                                      placeholder: "尺寸",
                                      min: 0,
                                      precision: 0,
                                      "controls-position": "right",
                                    },
                                    model: {
                                      value: _vm.activeData.size,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.activeData, "size", $$v)
                                      },
                                      expression: "activeData.size",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "默认值" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择" },
                                      model: {
                                        value: _vm.activeData.dataType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.activeData,
                                            "dataType",
                                            $$v
                                          )
                                        },
                                        expression: "activeData.dataType",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: {
                                          label: "固定值",
                                          value: "static",
                                        },
                                      }),
                                      _c("el-option", {
                                        attrs: {
                                          label: "组件联动",
                                          value: "relation",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.activeData.dataType === "static"
                                ? _c(
                                    "el-form-item",
                                    { attrs: { label: "固定值" } },
                                    [
                                      _c("el-input", {
                                        attrs: { placeholder: "请输入固定值" },
                                        model: {
                                          value: _vm.activeData.staticText,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.activeData,
                                              "staticText",
                                              $$v
                                            )
                                          },
                                          expression: "activeData.staticText",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.activeData.dataType === "relation"
                                ? _c(
                                    "el-form-item",
                                    { attrs: { label: "选择组件" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: "请选择",
                                            clearable: "",
                                            filterable: "",
                                          },
                                          model: {
                                            value: _vm.activeData.relationField,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.activeData,
                                                "relationField",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "activeData.relationField",
                                          },
                                        },
                                        _vm._l(
                                          _vm.drawingOptions,
                                          function (item, i) {
                                            return _c("el-option", {
                                              key: i,
                                              attrs: {
                                                label: item.__config__.label,
                                                value: item.__vModel__,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          : _vm._e(),
                        _vm.activeData.__config__.jnpfKey === "button"
                          ? [
                              _c(
                                "el-form-item",
                                { attrs: { label: "控件文本" } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入控件文本" },
                                    model: {
                                      value: _vm.activeData.buttonText,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.activeData,
                                          "buttonText",
                                          $$v
                                        )
                                      },
                                      expression: "activeData.buttonText",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "位置" } },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value: _vm.activeData.align,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.activeData, "align", $$v)
                                        },
                                        expression: "activeData.align",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio-button",
                                        { attrs: { label: "left" } },
                                        [_vm._v("居左")]
                                      ),
                                      _c(
                                        "el-radio-button",
                                        { attrs: { label: "center" } },
                                        [_vm._v("居中")]
                                      ),
                                      _c(
                                        "el-radio-button",
                                        { attrs: { label: "right" } },
                                        [_vm._v("居右")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "样式" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择" },
                                      model: {
                                        value: _vm.activeData.type,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.activeData, "type", $$v)
                                        },
                                        expression: "activeData.type",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "默认按钮", value: "" },
                                      }),
                                      _c("el-option", {
                                        attrs: {
                                          label: "主要按钮",
                                          value: "primary",
                                        },
                                      }),
                                      _c("el-option", {
                                        attrs: {
                                          label: "成功按钮",
                                          value: "success",
                                        },
                                      }),
                                      _c("el-option", {
                                        attrs: {
                                          label: "信息按钮",
                                          value: "info",
                                        },
                                      }),
                                      _c("el-option", {
                                        attrs: {
                                          label: "警告按钮",
                                          value: "warning",
                                        },
                                      }),
                                      _c("el-option", {
                                        attrs: {
                                          label: "危险按钮",
                                          value: "danger",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                        _vm.isSystem
                          ? [
                              _c(
                                "el-form-item",
                                { attrs: { label: "控件标题" } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入控件标题" },
                                    model: {
                                      value: _vm.activeData.__config__.label,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.activeData.__config__,
                                          "label",
                                          $$v
                                        )
                                      },
                                      expression: "activeData.__config__.label",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.activeData.__config__.jnpfKey === "billRule"
                                ? _c(
                                    "el-form-item",
                                    { attrs: { label: "单据模板" } },
                                    [
                                      _c("BillRule", {
                                        attrs: {
                                          value: _vm.activeData.__config__.rule,
                                          title:
                                            _vm.activeData.__config__.ruleName,
                                        },
                                        on: { change: _vm.onRuleChange },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.activeData.__config__.jnpfKey ===
                              "currOrganize"
                                ? _c(
                                    "el-form-item",
                                    { attrs: { label: "显示内容" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: "请选择显示内容",
                                          },
                                          model: {
                                            value: _vm.activeData.showLevel,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.activeData,
                                                "showLevel",
                                                $$v
                                              )
                                            },
                                            expression: "activeData.showLevel",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "显示组织",
                                              value: "all",
                                            },
                                          }),
                                          _c("el-option", {
                                            attrs: {
                                              label: "显示部门",
                                              value: "last",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "el-form-item",
                                { attrs: { label: "是否隐藏" } },
                                [
                                  _c("el-switch", {
                                    model: {
                                      value: _vm.activeData.__config__.noShow,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.activeData.__config__,
                                          "noShow",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "activeData.__config__.noShow",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                        _vm.activeData.on &&
                        (_vm.modelType == 1 || _vm.modelType == 6)
                          ? [
                              _c("el-divider", [_vm._v("脚本事件")]),
                              _vm._l(_vm.activeData.on, function (value, key) {
                                return _c(
                                  "div",
                                  { key: key },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: key } },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: { width: "100%" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.editFunc(value, key)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(_vm.getTipText(key)) +
                                                "\n                  "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }),
                            ]
                          : _vm._e(),
                        _vm.activeData.__config__.jnpfKey === "table"
                          ? [
                              _c(
                                "el-form-item",
                                { attrs: { label: "控件标题" } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入控件标题" },
                                    model: {
                                      value: _vm.activeData.__config__.label,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.activeData.__config__,
                                          "label",
                                          $$v
                                        )
                                      },
                                      expression: "activeData.__config__.label",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.$store.getters.hasTable
                                ? _c(
                                    "el-form-item",
                                    { attrs: { label: "关联子表" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: "请选择关联子表",
                                          },
                                          on: { change: _vm.onTableNameChange },
                                          model: {
                                            value:
                                              _vm.activeData.__config__
                                                .tableName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.activeData.__config__,
                                                "tableName",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "activeData.__config__.tableName",
                                          },
                                        },
                                        _vm._l(_vm.subTable, function (item) {
                                          return _c(
                                            "el-option",
                                            {
                                              key: item.table,
                                              attrs: {
                                                label: item.tableName
                                                  ? item.table +
                                                    "(" +
                                                    item.tableName +
                                                    ")"
                                                  : item.table,
                                                value: item.table,
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "custom-option-left",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        item.tableName
                                                          ? item.table +
                                                              "(" +
                                                              item.tableName +
                                                              ")"
                                                          : item.table
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "custom-option-right",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.typeId == "1"
                                                        ? "主表"
                                                        : "从表"
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c("table-config", {
                                attrs: { "active-data": _vm.activeData },
                              }),
                            ]
                          : _vm._e(),
                        _vm.activeData.__config__.jnpfKey === "card"
                          ? [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "卡片标题",
                                    "label-width": "76px",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入卡片标题" },
                                    model: {
                                      value: _vm.activeData.header,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.activeData, "header", $$v)
                                      },
                                      expression: "activeData.header",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.showType === "pc",
                                      expression: "showType==='pc'",
                                    },
                                  ],
                                  attrs: {
                                    label: "显示阴影",
                                    "label-width": "76px",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      attrs: { size: "small" },
                                      model: {
                                        value: _vm.activeData.shadow,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.activeData,
                                            "shadow",
                                            $$v
                                          )
                                        },
                                        expression: "activeData.shadow",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio-button",
                                        { attrs: { label: "always" } },
                                        [_vm._v("一直显示")]
                                      ),
                                      _c(
                                        "el-radio-button",
                                        { attrs: { label: "hover" } },
                                        [_vm._v("移入显示")]
                                      ),
                                      _c(
                                        "el-radio-button",
                                        { attrs: { label: "never" } },
                                        [_vm._v("永不显示")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                        _vm.activeData.__config__.jnpfKey === "row"
                          ? [
                              _vm.activeData.__config__.componentName !==
                              undefined
                                ? _c(
                                    "el-form-item",
                                    { attrs: { label: "组件名" } },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.activeData.__config__
                                              .componentName
                                          ) +
                                          "\n              "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          : _vm._e(),
                        _vm.activeData.__config__.layout === "rowFormItem" &&
                        _vm.activeData.gutter !== undefined
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "栅格间隔" } },
                              [
                                _c("el-input-number", {
                                  attrs: {
                                    min: 0,
                                    placeholder: "栅格间隔",
                                    "controls-position": "right",
                                  },
                                  model: {
                                    value: _vm.activeData.gutter,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.activeData, "gutter", $$v)
                                    },
                                    expression: "activeData.gutter",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "el-form",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === "style",
                      expression: "currentTab === 'style'",
                    },
                  ],
                  attrs: {
                    size: "small",
                    "label-width": "90px",
                    labelPosition: "left",
                  },
                },
                [
                  _vm.activeData.__config__
                    ? [
                        !_vm.activeData.__config__.isSubTable
                          ? [
                              _c(
                                "el-form-item",
                                { attrs: { label: "控件栅格" } },
                                [
                                  _c("el-slider", {
                                    attrs: {
                                      max: 24,
                                      min: 6,
                                      "show-stops": "",
                                      step: 2,
                                      "show-tooltip": "",
                                    },
                                    model: {
                                      value: _vm.activeData.__config__.span,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.activeData.__config__,
                                          "span",
                                          $$v
                                        )
                                      },
                                      expression: "activeData.__config__.span",
                                    },
                                  }),
                                ],
                                1
                              ),
                              ![
                                "divider",
                                "SNOWSText",
                                "link",
                                "alert",
                                "button",
                                "table",
                                "groupTitle ",
                                "card",
                                "row",
                                "tab",
                                "collapse",
                              ].includes(_vm.activeData.__config__.jnpfKey)
                                ? _c(
                                    "el-form-item",
                                    { attrs: { label: "标题宽度" } },
                                    [
                                      _c("el-input-number", {
                                        attrs: {
                                          placeholder: "标题宽度",
                                          min: 0,
                                          precision: 0,
                                          "controls-position": "right",
                                        },
                                        model: {
                                          value:
                                            _vm.activeData.__config__
                                              .labelWidth,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.activeData.__config__,
                                              "labelWidth",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "activeData.__config__.labelWidth",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          : _vm._e(),
                        !_vm.activeData.__config__.isSubTable &&
                        !_vm.noWithList.includes(
                          _vm.activeData.__config__.jnpfKey
                        ) &&
                        _vm.showType === "pc" &&
                        _vm.activeData.style
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  "tip-label": "支持%和px两种宽度设置",
                                  label: "控件宽度",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "控件宽度" },
                                  model: {
                                    value: _vm.activeData.style.width,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.activeData.style,
                                        "width",
                                        $$v
                                      )
                                    },
                                    expression: "activeData.style.width",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.activeData.__config__.isSubTable
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "控件宽度" } },
                              [
                                _c("el-input-number", {
                                  attrs: {
                                    placeholder: "控件宽度",
                                    min: 0,
                                    precision: 0,
                                    "controls-position": "right",
                                  },
                                  model: {
                                    value:
                                      _vm.activeData.__config__.columnWidth,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.activeData.__config__,
                                        "columnWidth",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "activeData.__config__.columnWidth",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-form-item",
                          { attrs: { label: "控件Class" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  multiple: "",
                                  clearable: "",
                                  placeholder: "请选择",
                                },
                                model: {
                                  value: _vm.activeData.__config__.className,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.activeData.__config__,
                                      "className",
                                      $$v
                                    )
                                  },
                                  expression: "activeData.__config__.className",
                                },
                              },
                              _vm._l(_vm.formConf.classNames, function (item) {
                                return _c("el-option", {
                                  key: item,
                                  attrs: { label: item, value: item },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "多端显示" } },
                          [
                            _c(
                              "el-checkbox-group",
                              {
                                attrs: { size: "mini" },
                                model: {
                                  value: _vm.activeData.__config__.visibility,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.activeData.__config__,
                                      "visibility",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "activeData.__config__.visibility",
                                },
                              },
                              [
                                _c("el-checkbox", { attrs: { label: "pc" } }, [
                                  _vm._v("Web"),
                                ]),
                                _c("el-checkbox", { attrs: { label: "app" } }, [
                                  _vm._v("App"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "el-form",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === "form",
                      expression: "currentTab === 'form'",
                    },
                  ],
                  attrs: {
                    size: "small",
                    "label-width": "100px",
                    labelPosition: "left",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "表单尺寸" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.formConf.size,
                            callback: function ($$v) {
                              _vm.$set(_vm.formConf, "size", $$v)
                            },
                            expression: "formConf.size",
                          },
                        },
                        [
                          _c(
                            "el-radio-button",
                            { attrs: { label: "medium" } },
                            [_vm._v("中等")]
                          ),
                          _c("el-radio-button", { attrs: { label: "small" } }, [
                            _vm._v("较小"),
                          ]),
                          _c("el-radio-button", { attrs: { label: "mini" } }, [
                            _vm._v("迷你"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "标签对齐" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.formConf.labelPosition,
                            callback: function ($$v) {
                              _vm.$set(_vm.formConf, "labelPosition", $$v)
                            },
                            expression: "formConf.labelPosition",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "left" } }, [
                            _vm._v("左对齐"),
                          ]),
                          _c("el-radio-button", { attrs: { label: "right" } }, [
                            _vm._v("右对齐"),
                          ]),
                          _c(
                            "el-radio-button",
                            {
                              attrs: {
                                label: "top",
                                disabled:
                                  _vm.formConf.formStyle === "word-form",
                              },
                            },
                            [_vm._v("顶部对齐\n              ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "标题宽度" } },
                    [
                      _c("el-input-number", {
                        attrs: {
                          min: 0,
                          placeholder: "标题宽度",
                          "controls-position": "right",
                        },
                        model: {
                          value: _vm.formConf.labelWidth,
                          callback: function ($$v) {
                            _vm.$set(_vm.formConf, "labelWidth", $$v)
                          },
                          expression: "formConf.labelWidth",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "栅格间隔" } },
                    [
                      _c("el-input-number", {
                        attrs: {
                          min: 0,
                          placeholder: "栅格间隔",
                          "controls-position": "right",
                        },
                        model: {
                          value: _vm.formConf.gutter,
                          callback: function ($$v) {
                            _vm.$set(_vm.formConf, "gutter", $$v)
                          },
                          expression: "formConf.gutter",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.modelType != 3 && _vm.modelType != 6 && _vm.webType == 2
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "弹窗类型" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.formConf.popupType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formConf, "popupType", $$v)
                                },
                                expression: "formConf.popupType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "居中弹窗", value: "general" },
                              }),
                              _c("el-option", {
                                attrs: { label: "右侧弹窗", value: "drawer" },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: "全屏弹窗",
                                  value: "fullScreen",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.modelType != 3
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "表单样式" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.formConf.formStyle,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formConf, "formStyle", $$v)
                                },
                                expression: "formConf.formStyle",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "默认风格", value: "" },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: "公文风格",
                                  value: "word-form",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.formConf.popupType === "general"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "表单宽度" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.formConf.generalWidth,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formConf, "generalWidth", $$v)
                                },
                                expression: "formConf.generalWidth",
                              },
                            },
                            _vm._l(_vm.generalWidthOptions, function (item) {
                              return _c("el-option", {
                                key: item,
                                attrs: { label: item, value: item },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.formConf.popupType === "fullScreen"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "表单宽度" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.formConf.fullScreenWidth,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formConf, "fullScreenWidth", $$v)
                                },
                                expression: "formConf.fullScreenWidth",
                              },
                            },
                            _vm._l(_vm.fullScreenWidthOptions, function (item) {
                              return _c("el-option", {
                                key: item,
                                attrs: { label: item, value: item },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.formConf.popupType === "drawer"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "表单宽度" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.formConf.drawerWidth,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formConf, "drawerWidth", $$v)
                                },
                                expression: "formConf.drawerWidth",
                              },
                            },
                            _vm._l(_vm.generalWidthOptions, function (item) {
                              return _c("el-option", {
                                key: item,
                                attrs: { label: item, value: item },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "表单Css" } },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "100%" },
                          on: { click: _vm.addStyle },
                        },
                        [_vm._v("编写样式")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "表单Class" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            multiple: "",
                            clearable: "",
                            placeholder: "请选择",
                          },
                          model: {
                            value: _vm.formConf.className,
                            callback: function ($$v) {
                              _vm.$set(_vm.formConf, "className", $$v)
                            },
                            expression: "formConf.className",
                          },
                        },
                        _vm._l(_vm.formConf.classNames, function (item) {
                          return _c("el-option", {
                            key: item,
                            attrs: { label: item, value: item },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "主键策略" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择",
                            disabled: !!_vm.getFormInfo().id,
                          },
                          model: {
                            value: _vm.formConf.primaryKeyPolicy,
                            callback: function ($$v) {
                              _vm.$set(_vm.formConf, "primaryKeyPolicy", $$v)
                            },
                            expression: "formConf.primaryKeyPolicy",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "雪花ID", value: 1 },
                          }),
                          _c("el-option", {
                            attrs: { label: "自增长ID", value: 2 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "span",
                        { attrs: { slot: "label" }, slot: "label" },
                        [
                          _vm._v("并发锁定\n              "),
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content:
                                  "并发提交表单时候，加乐观锁机制以保证数据提交准确性",
                                placement: "top",
                              },
                            },
                            [
                              _c("a", {
                                staticClass: "el-icon-warning-outline",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("el-switch", {
                        model: {
                          value: _vm.formConf.concurrencyLock,
                          callback: function ($$v) {
                            _vm.$set(_vm.formConf, "concurrencyLock", $$v)
                          },
                          expression: "formConf.concurrencyLock",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.modelType != 3 && _vm.modelType != 6 && _vm.webType != 3
                    ? [
                        _c("el-divider", [_vm._v("表单按钮")]),
                        _c(
                          "div",
                          { staticClass: "per-cell" },
                          [
                            _c(
                              "el-checkbox",
                              {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.formConf.hasConfirmBtn,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formConf, "hasConfirmBtn", $$v)
                                  },
                                  expression: "formConf.hasConfirmBtn",
                                },
                              },
                              [_vm._v("确定")]
                            ),
                            _c("el-input", {
                              model: {
                                value: _vm.formConf.confirmButtonText,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formConf,
                                    "confirmButtonText",
                                    $$v
                                  )
                                },
                                expression: "formConf.confirmButtonText",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "per-cell" },
                          [
                            _c(
                              "el-checkbox",
                              {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.formConf.hasCancelBtn,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formConf, "hasCancelBtn", $$v)
                                  },
                                  expression: "formConf.hasCancelBtn",
                                },
                              },
                              [_vm._v("取消")]
                            ),
                            _c("el-input", {
                              model: {
                                value: _vm.formConf.cancelButtonText,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formConf,
                                    "cancelButtonText",
                                    $$v
                                  )
                                },
                                expression: "formConf.cancelButtonText",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.webType == 2
                          ? [
                              _c(
                                "div",
                                {
                                  staticClass: "per-cell",
                                  class: { last: !_vm.formConf.hasPrintBtn },
                                },
                                [
                                  _c(
                                    "el-checkbox",
                                    {
                                      model: {
                                        value: _vm.formConf.hasPrintBtn,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formConf,
                                            "hasPrintBtn",
                                            $$v
                                          )
                                        },
                                        expression: "formConf.hasPrintBtn",
                                      },
                                    },
                                    [_vm._v("打印")]
                                  ),
                                  _c("el-input", {
                                    model: {
                                      value: _vm.formConf.printButtonText,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formConf,
                                          "printButtonText",
                                          $$v
                                        )
                                      },
                                      expression: "formConf.printButtonText",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.formConf.hasPrintBtn
                                ? _c(
                                    "el-form-item",
                                    { attrs: { label: "" } },
                                    [
                                      _c("SNOWS-TreeSelect", {
                                        attrs: {
                                          options: _vm.printTplList,
                                          placeholder: "请选择打印模板",
                                          lastLevel: "",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.formConf.printId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formConf,
                                              "printId",
                                              $$v
                                            )
                                          },
                                          expression: "formConf.printId",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  _vm.formConf.funcs &&
                  (_vm.modelType == 1 || _vm.modelType == 6)
                    ? [
                        _c("el-divider", [_vm._v("脚本事件")]),
                        _c(
                          "el-form-item",
                          { attrs: { label: "onLoad" } },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { width: "100%" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editFunc(
                                      _vm.formConf.funcs.onLoad,
                                      "onLoad",
                                      true
                                    )
                                  },
                                },
                              },
                              [_vm._v("表单加载触发\n              ")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "beforeSubmit" } },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { width: "100%" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editFunc(
                                      _vm.formConf.funcs.beforeSubmit,
                                      "beforeSubmit",
                                      true
                                    )
                                  },
                                },
                              },
                              [_vm._v("提交前置触发\n              ")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "afterSubmit" } },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { width: "100%" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editFunc(
                                      _vm.formConf.funcs.afterSubmit,
                                      "afterSubmit",
                                      true
                                    )
                                  },
                                },
                              },
                              [_vm._v("提交后置触发\n              ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("form-script", {
        attrs: {
          visible: _vm.formScriptVisible,
          tpl: _vm.activeScript,
          fields: _vm.drawingList,
        },
        on: {
          "update:visible": function ($event) {
            _vm.formScriptVisible = $event
          },
          updateScript: _vm.updateScript,
        },
      }),
      _c("field-dialog", {
        ref: "fieldDialog",
        attrs: { visible: _vm.fieldDialogVisible },
        on: {
          "update:visible": function ($event) {
            _vm.fieldDialogVisible = $event
          },
          close: function ($event) {
            _vm.fieldDialogVisible = false
          },
          updateOptions: _vm.updateFieldOptions,
        },
      }),
      _c("StyleScript", {
        attrs: {
          visible: _vm.styleScriptVisible,
          value: _vm.formConf.classJson,
        },
        on: {
          "update:visible": function ($event) {
            _vm.styleScriptVisible = $event
          },
          updateStyleScript: _vm.updateStyleScript,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }