var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    _vm._g(
      _vm._b(
        {
          staticClass: "SNOWS-dialog SNOWS-dialog_center form-script-dialog",
          attrs: {
            "close-on-click-modal": false,
            "lock-scroll": "",
            "append-to-body": "",
            width: "1100px",
            "modal-append-to-body": false,
          },
          on: { open: _vm.onOpen },
        },
        "el-dialog",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "span",
        {
          staticClass: "dialog-title",
          attrs: { slot: "title" },
          slot: "title",
        },
        [
          _c(
            "span",
            [
              _vm._v("表单脚本\n      "),
              _c(
                "el-tooltip",
                {
                  attrs: {
                    content: "小程序不支持在线JS脚本",
                    placement: "top-start",
                  },
                },
                [_c("a", { staticClass: "el-icon-warning-outline" })]
              ),
            ],
            1
          ),
        ]
      ),
      _c("div", { staticClass: "form-script-dialog-body" }, [
        _c(
          "div",
          { staticClass: "left-tree" },
          [
            _c("el-tree", {
              attrs: {
                data: _vm.treeData,
                "default-expand-all": "",
                "expand-on-click-node": false,
                props: _vm.treeProps,
              },
              on: { "node-click": _vm.handleNodeClick },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "right-main" }, [
          _c(
            "div",
            { staticClass: "codeEditor" },
            [
              _c("JNPFCodeEditor", {
                ref: "CodeEditor",
                attrs: { options: _vm.options },
                model: {
                  value: _vm.text,
                  callback: function ($$v) {
                    _vm.text = $$v
                  },
                  expression: "text",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "tips" }, [
            _c("p", [
              _vm._v(
                "请从左侧面板选择的字段名，支持JavaScript的脚本，参考编写脚本API"
              ),
            ]),
            _c("p", [
              _vm._v(
                "data--当前组件的选中数据，formData--表单数据，setFormData--设置表单某个组件数据(prop,value)"
              ),
            ]),
            _c("p", [
              _vm._v(
                "\n          setShowOrHide--设置显示或隐藏(prop,value)，setRequired--设置必填项(prop,value)\n        "
              ),
            ]),
            _c("p", [
              _vm._v(
                "\n          setDisabled--设置禁用项(prop,value)，request--异步请求(url,method,data)\n        "
              ),
            ]),
          ]),
        ]),
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.closeDialog } }, [_vm._v("取消")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.onClose()
                },
              },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }