var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "left-board" },
        [
          _c("el-scrollbar", { staticClass: "left-scrollbar" }, [
            _c(
              "div",
              { staticClass: "components-list" },
              _vm._l(_vm.leftComponents, function (item, listIndex) {
                return _c(
                  "div",
                  { key: listIndex, staticClass: "components-part" },
                  [
                    _c("div", { staticClass: "components-title" }, [
                      _vm._v(_vm._s(item.title)),
                    ]),
                    _c(
                      "draggable",
                      {
                        staticClass: "components-draggable",
                        attrs: {
                          list: item.list,
                          group: {
                            name: "componentsGroup",
                            pull: "clone",
                            put: false,
                          },
                          clone: _vm.cloneComponent,
                          draggable: ".components-item",
                          filter: ".disabled",
                          sort: false,
                        },
                        on: { end: _vm.onEnd },
                      },
                      _vm._l(item.list, function (element, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "components-item",
                            class: {
                              disabled: element.__config__.dragDisabled,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.addComponent(element)
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "components-body" }, [
                              _c("i", { class: element.__config__.tagIcon }),
                              _vm._v(
                                "\n                " +
                                  _vm._s(element.__config__.label) +
                                  "\n              "
                              ),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "center-board" },
        [
          _c("div", { staticClass: "action-bar" }, [
            _c(
              "div",
              { staticClass: "action-bar-right" },
              [
                _c(
                  "el-button",
                  {
                    class: { "unActive-btn": _vm.showType != "pc" },
                    attrs: {
                      icon: "icon-ym icon-ym-pc",
                      type: "text",
                      size: "medium",
                    },
                    on: {
                      click: function ($event) {
                        _vm.showType = "pc"
                      },
                    },
                  },
                  [_vm._v("桌面端")]
                ),
                _c(
                  "el-button",
                  {
                    class: { "unActive-btn": _vm.showType != "app" },
                    attrs: {
                      icon: "icon-ym icon-ym-mobile",
                      type: "text",
                      size: "medium",
                    },
                    on: {
                      click: function ($event) {
                        _vm.showType = "app"
                      },
                    },
                  },
                  [_vm._v("移动端")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "unActive-btn",
                    attrs: {
                      icon: "el-icon-video-play",
                      type: "text",
                      size: "medium",
                    },
                    on: { click: _vm.preview },
                  },
                  [_vm._v("预览")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "delete-btn unActive-btn",
                    attrs: {
                      icon: "icon-ym icon-ym-clean",
                      type: "text",
                      size: "medium",
                    },
                    on: { click: _vm.empty },
                  },
                  [_vm._v("清空")]
                ),
              ],
              1
            ),
          ]),
          _c(
            "el-scrollbar",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showType === "pc",
                  expression: "showType==='pc'",
                },
              ],
              staticClass: "center-scrollbar",
            },
            [
              _c(
                "el-row",
                {
                  staticClass: "center-board-row",
                  attrs: { gutter: _vm.formConf.gutter },
                },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        size: _vm.formConf.size,
                        "label-position": _vm.formConf.labelPosition,
                        disabled: _vm.formConf.disabled,
                        "label-width": _vm.formConf.labelWidth + "px",
                      },
                    },
                    [
                      _c(
                        "draggable",
                        {
                          staticClass: "drawing-board",
                          attrs: {
                            list: _vm.drawingList,
                            animation: 340,
                            group: "componentsGroup",
                          },
                          on: { end: _vm.onCenterEnd },
                        },
                        _vm._l(_vm.drawingList, function (element, index) {
                          return _c("draggable-item", {
                            key: element.renderKey,
                            attrs: {
                              "drawing-list": _vm.drawingList,
                              element: element,
                              index: index,
                              "active-id": _vm.activeId,
                              "form-conf": _vm.formConf,
                              put: _vm.shouldClone,
                              end: _vm.onTableEnd,
                            },
                            on: {
                              activeItem: _vm.activeFormItem,
                              copyItem: _vm.drawingItemCopy,
                              deleteItem: _vm.drawingItemDelete,
                            },
                          })
                        }),
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.drawingList.length,
                              expression: "!drawingList.length",
                            },
                          ],
                          staticClass: "empty-info",
                        },
                        [
                          _c("img", {
                            staticClass: "empty-img",
                            attrs: {
                              src: require("@/assets/images/emptyElement.png"),
                              alt: "",
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showType === "app",
                  expression: "showType==='app'",
                },
              ],
              attrs: { id: "ipad" },
            },
            [
              _c("div", { staticClass: "outeripad" }, [
                _c(
                  "div",
                  { staticClass: "ipadbody" },
                  [
                    _c(
                      "el-scrollbar",
                      { staticClass: "center-scrollbar" },
                      [
                        _c(
                          "el-row",
                          {
                            staticClass: "center-board-row",
                            attrs: { gutter: _vm.formConf.gutter },
                          },
                          [
                            _c(
                              "el-form",
                              {
                                attrs: {
                                  size: _vm.formConf.size,
                                  "label-position": _vm.formConf.labelPosition,
                                  disabled: _vm.formConf.disabled,
                                  "label-width": _vm.formConf.labelWidth + "px",
                                },
                              },
                              [
                                _c(
                                  "draggable",
                                  {
                                    staticClass: "drawing-board",
                                    attrs: {
                                      list: _vm.drawingList,
                                      animation: 340,
                                      group: "componentsGroup",
                                    },
                                    on: { end: _vm.onCenterEnd },
                                  },
                                  _vm._l(
                                    _vm.drawingList,
                                    function (element, index) {
                                      return _c("draggable-item-app", {
                                        key: element.renderKey,
                                        attrs: {
                                          "drawing-list": _vm.drawingList,
                                          element: element,
                                          index: index,
                                          "active-id": _vm.activeId,
                                          "form-conf": _vm.formConf,
                                          put: _vm.shouldClone,
                                          end: _vm.onTableEnd,
                                        },
                                        on: {
                                          activeItem: _vm.activeFormItem,
                                          copyItem: _vm.drawingItemCopy,
                                          deleteItem: _vm.drawingItemDelete,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !_vm.drawingList.length,
                                        expression: "!drawingList.length",
                                      },
                                    ],
                                    staticClass: "empty-info app-empty-info",
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "empty-img",
                                      attrs: {
                                        src: require("@/assets/images/emptyElement.png"),
                                        alt: "",
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("right-panel", {
        attrs: {
          "active-data": _vm.activeData,
          "form-conf": _vm.formConf,
          "show-field": !!_vm.drawingList.length,
          modelType: _vm.modelType,
          webType: _vm.webType,
          drawingList: _vm.drawingList,
        },
        on: { "tag-change": _vm.tagChange, relationChange: _vm.relationChange },
      }),
      _c("json-drawer", {
        attrs: {
          size: "550px",
          visible: _vm.jsonDrawerVisible,
          jsonData: _vm.formData,
        },
        on: {
          "update:visible": function ($event) {
            _vm.jsonDrawerVisible = $event
          },
          refresh: _vm.refreshJson,
        },
      }),
      _c("Preview", {
        attrs: { visible: _vm.previewVisible, "form-data": _vm.formData },
        on: {
          "update:visible": function ($event) {
            _vm.previewVisible = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }