var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    [
      _c(
        "el-form-item",
        { attrs: { label: "控件标题" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入控件标题" },
            model: {
              value: _vm.activeData.__config__.label,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.__config__, "label", $$v)
              },
              expression: "activeData.__config__.label",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "占位提示" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入占位提示" },
            model: {
              value: _vm.activeData.placeholder,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "placeholder", $$v)
              },
              expression: "activeData.placeholder",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "默认值" } },
        [
          _c("el-input", {
            attrs: {
              value: _vm.setDefaultValue(
                _vm.activeData.__config__.defaultValue
              ),
              placeholder: "请输入默认值",
            },
            on: { input: _vm.onDefaultValueInput },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showType === "pc",
              expression: "showType==='pc'",
            },
          ],
          attrs: { label: "前缀" },
        },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入前缀" },
            model: {
              value: _vm.activeData.__slot__.prepend,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.__slot__, "prepend", $$v)
              },
              expression: "activeData.__slot__.prepend",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showType === "pc",
              expression: "showType==='pc'",
            },
          ],
          attrs: { label: "后缀" },
        },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入后缀" },
            model: {
              value: _vm.activeData.__slot__.append,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.__slot__, "append", $$v)
              },
              expression: "activeData.__slot__.append",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showType === "pc",
              expression: "showType==='pc'",
            },
          ],
          attrs: { label: "前图标" },
        },
        [
          _c(
            "el-input",
            {
              attrs: { placeholder: "请输入前图标名称" },
              model: {
                value: _vm.activeData["prefix-icon"],
                callback: function ($$v) {
                  _vm.$set(_vm.activeData, "prefix-icon", $$v)
                },
                expression: "activeData['prefix-icon']",
              },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { slot: "append" },
                  on: {
                    click: function ($event) {
                      return _vm.openIconsDialog("prefix-icon")
                    },
                  },
                  slot: "append",
                },
                [_vm._v("\n        选择\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showType === "pc",
              expression: "showType==='pc'",
            },
          ],
          attrs: { label: "后图标" },
        },
        [
          _c(
            "el-input",
            {
              attrs: { placeholder: "请输入后图标名称" },
              model: {
                value: _vm.activeData["suffix-icon"],
                callback: function ($$v) {
                  _vm.$set(_vm.activeData, "suffix-icon", $$v)
                },
                expression: "activeData['suffix-icon']",
              },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { slot: "append" },
                  on: {
                    click: function ($event) {
                      return _vm.openIconsDialog("suffix-icon")
                    },
                  },
                  slot: "append",
                },
                [_vm._v("\n        选择\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "最多输入" } },
        [
          _c(
            "el-input",
            {
              attrs: { placeholder: "请输入字符长度", type: "number" },
              model: {
                value: _vm.activeData.maxlength,
                callback: function ($$v) {
                  _vm.$set(_vm.activeData, "maxlength", $$v)
                },
                expression: "activeData.maxlength",
              },
            },
            [_c("template", { slot: "append" }, [_vm._v("个字符")])],
            2
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "启用流水号" } },
        [
          _c("el-switch", {
            on: { change: _vm.changeSerial },
            model: {
              value: _vm.activeData.serialnumber,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "serialnumber", $$v)
              },
              expression: "activeData.serialnumber",
            },
          }),
        ],
        1
      ),
      _vm.activeData.serialnumber == true
        ? _c(
            "el-form-item",
            { attrs: { label: "选择流水号" } },
            [
              _c(
                "el-select",
                {
                  attrs: { filterable: "", placeholder: "请选择流水号" },
                  model: {
                    value: _vm.activeData.serialcode,
                    callback: function ($$v) {
                      _vm.$set(_vm.activeData, "serialcode", $$v)
                    },
                    expression: "activeData.serialcode",
                  },
                },
                _vm._l(_vm.SerialData, function (item) {
                  return _c("el-option", {
                    key: item.ruleCode,
                    attrs: { label: item.ruleName, value: item.ruleCode },
                  })
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-form-item",
        { attrs: { label: "能否清空" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData.clearable,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "clearable", $$v)
              },
              expression: "activeData.clearable",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "是否密码" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData["show-password"],
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "show-password", $$v)
              },
              expression: "activeData['show-password']",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "是否只读" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData.readonly,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "readonly", $$v)
              },
              expression: "activeData.readonly",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "是否禁用" } },
        [
          _c("el-switch", {
            attrs: { disabled: _vm.activeData.serialnumber },
            model: {
              value: _vm.activeData.disabled,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "disabled", $$v)
              },
              expression: "activeData.disabled",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "是否隐藏" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData.__config__.noShow,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.__config__, "noShow", $$v)
              },
              expression: "activeData.__config__.noShow",
            },
          }),
        ],
        1
      ),
      _c("el-divider", [_vm._v("校验规则")]),
      _c(
        "el-form-item",
        { attrs: { label: "是否必填" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData.__config__.required,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.__config__, "required", $$v)
              },
              expression: "activeData.__config__.required",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c(
            "span",
            { attrs: { slot: "label" }, slot: "label" },
            [
              _vm._v("是否唯一\n      "),
              _c(
                "el-tooltip",
                { attrs: { content: "输入值唯一性校验", placement: "top" } },
                [_c("a", { staticClass: "el-icon-warning-outline" })]
              ),
            ],
            1
          ),
          _c("el-switch", {
            model: {
              value: _vm.activeData.__config__.unique,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.__config__, "unique", $$v)
              },
              expression: "activeData.__config__.unique",
            },
          }),
        ],
        1
      ),
      _vm._l(_vm.activeData.__config__.regList, function (item, index) {
        return _c(
          "div",
          { key: index, staticClass: "reg-item" },
          [
            _c(
              "span",
              {
                staticClass: "close-btn",
                on: {
                  click: function ($event) {
                    return _vm.activeData.__config__.regList.splice(index, 1)
                  },
                },
              },
              [_c("i", { staticClass: "el-icon-close" })]
            ),
            _c(
              "el-form-item",
              { attrs: { label: "表达式" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入正则" },
                  model: {
                    value: item.pattern,
                    callback: function ($$v) {
                      _vm.$set(item, "pattern", $$v)
                    },
                    expression: "item.pattern",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                staticStyle: { "margin-bottom": "0" },
                attrs: { label: "错误提示" },
              },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入错误提示" },
                  model: {
                    value: item.message,
                    callback: function ($$v) {
                      _vm.$set(item, "message", $$v)
                    },
                    expression: "item.message",
                  },
                }),
              ],
              1
            ),
          ],
          1
        )
      }),
      _c(
        "div",
        { staticClass: "mt-10" },
        [
          _c(
            "el-dropdown",
            [
              _c("el-button", { attrs: { type: "primary" } }, [
                _vm._v("添加常用校验"),
                _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
              ]),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                _vm._l(_vm.ruleList, function (item, i) {
                  return _c(
                    "el-dropdown-item",
                    {
                      key: i,
                      nativeOn: {
                        click: function ($event) {
                          return _vm.addHandle(item)
                        },
                      },
                    },
                    [_vm._v("\n          " + _vm._s(item.label))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "10px" },
              attrs: { type: "primary" },
              on: { click: _vm.addReg },
            },
            [_vm._v("\n      自定义规则\n    ")]
          ),
        ],
        1
      ),
      _c("iconBox", {
        attrs: {
          visible: _vm.iconsVisible,
          current: _vm.activeData[_vm.currentIconModel],
        },
        on: {
          "update:visible": function ($event) {
            _vm.iconsVisible = $event
          },
          choiceIcon: _vm.setIcon,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }