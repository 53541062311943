var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    [
      _c(
        "el-form-item",
        { attrs: { label: "控件标题" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入控件标题" },
            model: {
              value: _vm.activeData.__config__.label,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.__config__, "label", $$v)
              },
              expression: "activeData.__config__.label",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "占位提示" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入占位提示" },
            model: {
              value: _vm.activeData.placeholder,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "placeholder", $$v)
              },
              expression: "activeData.placeholder",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "默认值" } },
        [
          _c(
            "el-select",
            {
              attrs: {
                placeholder: "选择默认值",
                clearable: "",
                multiple: _vm.activeData.multiple,
                filterable: "",
              },
              model: {
                value: _vm.activeData.__config__.defaultValue,
                callback: function ($$v) {
                  _vm.$set(_vm.activeData.__config__, "defaultValue", $$v)
                },
                expression: "activeData.__config__.defaultValue",
              },
            },
            _vm._l(_vm.activeData.__slot__.options, function (item, i) {
              return _c("el-option", {
                key: i,
                attrs: {
                  label: item[_vm.activeData.__config__.props.label],
                  value: item[_vm.activeData.__config__.props.value],
                },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c("el-divider", [_vm._v("数据选项")]),
      _c(
        "el-form-item",
        { attrs: { label: "", "label-width": "40px" } },
        [
          _c(
            "el-radio-group",
            {
              staticStyle: { "text-align": "center" },
              attrs: { size: "small" },
              on: { change: _vm.dataTypeChange },
              model: {
                value: _vm.activeData.__config__.dataType,
                callback: function ($$v) {
                  _vm.$set(_vm.activeData.__config__, "dataType", $$v)
                },
                expression: "activeData.__config__.dataType",
              },
            },
            [
              _c("el-radio-button", { attrs: { label: "static" } }, [
                _vm._v("静态数据"),
              ]),
              _c("el-radio-button", { attrs: { label: "dictionary" } }, [
                _vm._v("数据字典"),
              ]),
              _c("el-radio-button", { attrs: { label: "dynamic" } }, [
                _vm._v("远端数据"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.activeData.__config__.dataType === "static"
        ? [
            _c(
              "draggable",
              {
                attrs: {
                  list: _vm.activeData.__slot__.options,
                  animation: 340,
                  group: "selectItem",
                  handle: ".option-drag",
                },
              },
              _vm._l(_vm.activeData.__slot__.options, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "select-item" },
                  [
                    _c("div", { staticClass: "select-line-icon option-drag" }, [
                      _c("i", { staticClass: "icon-ym icon-ym-darg" }),
                    ]),
                    _c("el-input", {
                      attrs: { placeholder: "选项名", size: "small" },
                      model: {
                        value: item.fullName,
                        callback: function ($$v) {
                          _vm.$set(item, "fullName", $$v)
                        },
                        expression: "item.fullName",
                      },
                    }),
                    _c("el-input", {
                      attrs: { placeholder: "选项值", size: "small" },
                      model: {
                        value: item.id,
                        callback: function ($$v) {
                          _vm.$set(item, "id", $$v)
                        },
                        expression: "item.id",
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "close-btn select-line-icon",
                        on: {
                          click: function ($event) {
                            return _vm.activeData.__slot__.options.splice(
                              index,
                              1
                            )
                          },
                        },
                      },
                      [_c("i", { staticClass: "el-icon-remove-outline" })]
                    ),
                  ],
                  1
                )
              }),
              0
            ),
            _c(
              "div",
              { staticStyle: { "margin-left": "29px" } },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { "padding-bottom": "0" },
                    attrs: {
                      icon: "el-icon-circle-plus-outline",
                      type: "text",
                    },
                    on: { click: _vm.addSelectItem },
                  },
                  [_vm._v("\n        添加选项\n      ")]
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm.activeData.__config__.dataType === "dictionary"
        ? [
            _c(
              "el-form-item",
              { attrs: { label: "数据字典" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { filterable: "", placeholder: "请选择数据字典" },
                    on: { change: _vm.dictionaryTypeChange },
                    model: {
                      value: _vm.activeData.__config__.dictionaryType,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeData.__config__,
                          "dictionaryType",
                          $$v
                        )
                      },
                      expression: "activeData.__config__.dictionaryType",
                    },
                  },
                  _vm._l(_vm.dicOptions, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.dictValue, value: item.id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "存储字段" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "请选择存储字段" },
                    model: {
                      value: _vm.activeData.__config__.props.value,
                      callback: function ($$v) {
                        _vm.$set(_vm.activeData.__config__.props, "value", $$v)
                      },
                      expression: "activeData.__config__.props.value",
                    },
                  },
                  [
                    _c("el-option", { attrs: { label: "id", value: "id" } }),
                    _c("el-option", {
                      attrs: { label: "enCode", value: "enCode" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm.activeData.__config__.dataType === "dynamic"
        ? [
            _c(
              "el-form-item",
              { attrs: { label: "远端数据" } },
              [
                _c("interface-dialog", {
                  attrs: {
                    value: _vm.activeData.__config__.propsUrl,
                    title: _vm.activeData.__config__.propsName,
                    popupTitle: "远端数据",
                  },
                  on: { change: _vm.propsUrlChange },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "存储字段" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入存储字段" },
                  model: {
                    value: _vm.activeData.__config__.props.value,
                    callback: function ($$v) {
                      _vm.$set(_vm.activeData.__config__.props, "value", $$v)
                    },
                    expression: "activeData.__config__.props.value",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "显示字段" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入显示字段" },
                  model: {
                    value: _vm.activeData.__config__.props.label,
                    callback: function ($$v) {
                      _vm.$set(_vm.activeData.__config__.props, "label", $$v)
                    },
                    expression: "activeData.__config__.props.label",
                  },
                }),
              ],
              1
            ),
            _vm.activeData.__config__.templateJson &&
            _vm.activeData.__config__.templateJson.length
              ? _c(
                  "el-table",
                  { attrs: { data: _vm.activeData.__config__.templateJson } },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "index",
                        width: "50",
                        label: "序号",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "field", label: "参数名称" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", { staticClass: "required-sign" }, [
                                  _vm._v(_vm._s(scope.row.required ? "*" : "")),
                                ]),
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      scope.row.fieldName
                                        ? scope.row.field +
                                            "(" +
                                            scope.row.fieldName +
                                            ")"
                                        : scope.row.field
                                    ) +
                                    "\n        "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        284133495
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "value", label: "表单字段" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: "请选择表单字段",
                                      clearable: "",
                                      filterable: "",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.onRelationFieldChange(
                                          $event,
                                          scope.row
                                        )
                                      },
                                    },
                                    model: {
                                      value: scope.row.relationField,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          scope.row,
                                          "relationField",
                                          $$v
                                        )
                                      },
                                      expression: "scope.row.relationField",
                                    },
                                  },
                                  _vm._l(
                                    _vm.formFieldsOptions,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.realVModel,
                                        attrs: {
                                          label: item.realLabel,
                                          value: item.realVModel,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3708884993
                      ),
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        : _vm._e(),
      _c("el-divider"),
      _c(
        "el-form-item",
        { attrs: { label: "能否清空" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData.clearable,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "clearable", $$v)
              },
              expression: "activeData.clearable",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showType === "pc",
              expression: "showType==='pc'",
            },
          ],
          attrs: { label: "能否搜索" },
        },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData.filterable,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "filterable", $$v)
              },
              expression: "activeData.filterable",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "能否多选" } },
        [
          _c("el-switch", {
            on: { change: _vm.multipleChange },
            model: {
              value: _vm.activeData.multiple,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "multiple", $$v)
              },
              expression: "activeData.multiple",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "是否禁用" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData.disabled,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "disabled", $$v)
              },
              expression: "activeData.disabled",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "是否必填" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData.__config__.required,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.__config__, "required", $$v)
              },
              expression: "activeData.__config__.required",
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }