var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    [
      _c(
        "el-form-item",
        { attrs: { label: "控件标题" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入控件标题" },
            model: {
              value: _vm.activeData.__config__.label,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.__config__, "label", $$v)
              },
              expression: "activeData.__config__.label",
            },
          }),
        ],
        1
      ),
      _vm.activeData.__config__.jnpfKey !== "editor"
        ? _c(
            "el-form-item",
            { attrs: { label: "占位提示" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入占位提示" },
                model: {
                  value: _vm.activeData.placeholder,
                  callback: function ($$v) {
                    _vm.$set(_vm.activeData, "placeholder", $$v)
                  },
                  expression: "activeData.placeholder",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      ["depSelect", "posSelect", "usersSelect"].includes(
        _vm.activeData.__config__.jnpfKey
      )
        ? _c(
            "el-form-item",
            { attrs: { label: "可选范围" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  on: { change: _vm.onTypeChange },
                  model: {
                    value: _vm.activeData.selectType,
                    callback: function ($$v) {
                      _vm.$set(_vm.activeData, "selectType", $$v)
                    },
                    expression: "activeData.selectType",
                  },
                },
                _vm._l(_vm.selectTypeOptions, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
              _vm.activeData.selectType === "custom"
                ? [
                    _vm.activeData.__config__.jnpfKey === "depSelect"
                      ? [
                          _c("org-Select", {
                            staticClass: "mt-10",
                            attrs: { title: "添加部门", type: "department" },
                            model: {
                              value: _vm.activeData.ableDepIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.activeData, "ableDepIds", $$v)
                              },
                              expression: "activeData.ableDepIds",
                            },
                          }),
                        ]
                      : _vm._e(),
                    _vm.activeData.__config__.jnpfKey === "posSelect"
                      ? [
                          _c("org-Select", {
                            staticClass: "mt-10",
                            attrs: { title: "添加部门", type: "department" },
                            model: {
                              value: _vm.activeData.ableDepIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.activeData, "ableDepIds", $$v)
                              },
                              expression: "activeData.ableDepIds",
                            },
                          }),
                          _c("org-Select", {
                            staticClass: "mt-10",
                            attrs: { title: "添加岗位", type: "position" },
                            model: {
                              value: _vm.activeData.ablePosIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.activeData, "ablePosIds", $$v)
                              },
                              expression: "activeData.ablePosIds",
                            },
                          }),
                        ]
                      : _vm._e(),
                    _vm.activeData.__config__.jnpfKey === "usersSelect"
                      ? [
                          _c("org-Select", {
                            staticClass: "mt-10",
                            attrs: { title: "添加用户", type: "users" },
                            model: {
                              value: _vm.activeData.ableIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.activeData, "ableIds", $$v)
                              },
                              expression: "activeData.ableIds",
                            },
                          }),
                        ]
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm.activeData.__config__.jnpfKey === "userSelect"
        ? _c(
            "el-form-item",
            { attrs: { label: "可选范围" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  on: { change: _vm.onTypeChange },
                  model: {
                    value: _vm.activeData.selectType,
                    callback: function ($$v) {
                      _vm.$set(_vm.activeData, "selectType", $$v)
                    },
                    expression: "activeData.selectType",
                  },
                },
                _vm._l(_vm.userSelectTypeOptions, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
              _vm.activeData.selectType === "custom"
                ? [
                    _c("org-Select", {
                      staticClass: "mt-10",
                      attrs: { title: "添加部门", type: "department" },
                      model: {
                        value: _vm.activeData.ableDepIds,
                        callback: function ($$v) {
                          _vm.$set(_vm.activeData, "ableDepIds", $$v)
                        },
                        expression: "activeData.ableDepIds",
                      },
                    }),
                    _c("org-Select", {
                      staticClass: "mt-10",
                      attrs: { title: "添加岗位", type: "position" },
                      model: {
                        value: _vm.activeData.ablePosIds,
                        callback: function ($$v) {
                          _vm.$set(_vm.activeData, "ablePosIds", $$v)
                        },
                        expression: "activeData.ablePosIds",
                      },
                    }),
                    _c("org-Select", {
                      staticClass: "mt-10",
                      attrs: { title: "添加用户", type: "user" },
                      model: {
                        value: _vm.activeData.ableUserIds,
                        callback: function ($$v) {
                          _vm.$set(_vm.activeData, "ableUserIds", $$v)
                        },
                        expression: "activeData.ableUserIds",
                      },
                    }),
                    _c("org-Select", {
                      staticClass: "mt-10",
                      attrs: { title: "添加角色", type: "role" },
                      model: {
                        value: _vm.activeData.ableRoleIds,
                        callback: function ($$v) {
                          _vm.$set(_vm.activeData, "ableRoleIds", $$v)
                        },
                        expression: "activeData.ableRoleIds",
                      },
                    }),
                    _c("org-Select", {
                      staticClass: "mt-10",
                      attrs: { title: "添加分组", type: "group" },
                      model: {
                        value: _vm.activeData.ableGroupIds,
                        callback: function ($$v) {
                          _vm.$set(_vm.activeData, "ableGroupIds", $$v)
                        },
                        expression: "activeData.ableGroupIds",
                      },
                    }),
                  ]
                : _vm._e(),
              ["dep", "pos", "role", "group"].includes(
                _vm.activeData.selectType
              )
                ? [
                    _c(
                      "el-radio-group",
                      {
                        staticClass: "relation-radio",
                        model: {
                          value: _vm.activeData.relationField,
                          callback: function ($$v) {
                            _vm.$set(_vm.activeData, "relationField", $$v)
                          },
                          expression: "activeData.relationField",
                        },
                      },
                      _vm._l(_vm.formFieldsOptions, function (item, i) {
                        return _c(
                          "el-radio",
                          { key: i, attrs: { label: item.realVModel } },
                          [_vm._v("\n          " + _vm._s(item.realLabel))]
                        )
                      }),
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _c(
        "el-form-item",
        { attrs: { label: "默认值" } },
        [
          _vm.activeData.__config__.jnpfKey === "comSelect"
            ? _c("com-select", {
                key: _vm.key,
                attrs: {
                  placeholder: "选择默认值",
                  clearable: "",
                  multiple: _vm.activeData.multiple,
                },
                model: {
                  value: _vm.activeData.__config__.defaultValue,
                  callback: function ($$v) {
                    _vm.$set(_vm.activeData.__config__, "defaultValue", $$v)
                  },
                  expression: "activeData.__config__.defaultValue",
                },
              })
            : _vm._e(),
          _vm.activeData.__config__.jnpfKey === "depSelect"
            ? _c("dep-select", {
                key: _vm.key,
                attrs: {
                  placeholder: "选择默认值",
                  clearable: "",
                  multiple: _vm.activeData.multiple,
                  selectType: _vm.activeData.selectType,
                  ableDepIds: _vm.activeData.ableDepIds,
                },
                model: {
                  value: _vm.activeData.__config__.defaultValue,
                  callback: function ($$v) {
                    _vm.$set(_vm.activeData.__config__, "defaultValue", $$v)
                  },
                  expression: "activeData.__config__.defaultValue",
                },
              })
            : _vm._e(),
          _vm.activeData.__config__.jnpfKey === "posSelect"
            ? _c("pos-select", {
                key: _vm.key,
                attrs: {
                  placeholder: "选择默认值",
                  clearable: "",
                  multiple: _vm.activeData.multiple,
                  selectType: _vm.activeData.selectType,
                  ableDepIds: _vm.activeData.ableDepIds,
                  ablePosIds: _vm.activeData.ablePosIds,
                },
                model: {
                  value: _vm.activeData.__config__.defaultValue,
                  callback: function ($$v) {
                    _vm.$set(_vm.activeData.__config__, "defaultValue", $$v)
                  },
                  expression: "activeData.__config__.defaultValue",
                },
              })
            : _vm._e(),
          _vm.activeData.__config__.jnpfKey === "userSelect"
            ? _c("user-select", {
                key: _vm.key,
                attrs: {
                  placeholder: "选择默认值",
                  clearable: "",
                  multiple: _vm.activeData.multiple,
                  selectType: _vm.activeData.selectType,
                  ableDepIds: _vm.activeData.ableDepIds,
                  ablePosIds: _vm.activeData.ablePosIds,
                  ableUserIds: _vm.activeData.ableUserIds,
                  ableRoleIds: _vm.activeData.ableRoleIds,
                  ableGroupIds: _vm.activeData.ableGroupIds,
                },
                model: {
                  value: _vm.activeData.__config__.defaultValue,
                  callback: function ($$v) {
                    _vm.$set(_vm.activeData.__config__, "defaultValue", $$v)
                  },
                  expression: "activeData.__config__.defaultValue",
                },
              })
            : _vm._e(),
          _vm.activeData.__config__.jnpfKey === "usersSelect"
            ? _c("users-select", {
                key: _vm.key,
                attrs: {
                  placeholder: "选择默认值",
                  clearable: "",
                  multiple: _vm.activeData.multiple,
                  selectType: _vm.activeData.selectType,
                  ableIds: _vm.activeData.ableIds,
                },
                model: {
                  value: _vm.activeData.__config__.defaultValue,
                  callback: function ($$v) {
                    _vm.$set(_vm.activeData.__config__, "defaultValue", $$v)
                  },
                  expression: "activeData.__config__.defaultValue",
                },
              })
            : _vm._e(),
          _vm.activeData.__config__.jnpfKey === "groupSelect"
            ? _c("group-select", {
                attrs: {
                  placeholder: "选择默认值",
                  clearable: "",
                  multiple: _vm.activeData.multiple,
                },
                model: {
                  value: _vm.activeData.__config__.defaultValue,
                  callback: function ($$v) {
                    _vm.$set(_vm.activeData.__config__, "defaultValue", $$v)
                  },
                  expression: "activeData.__config__.defaultValue",
                },
              })
            : _vm._e(),
          _vm.activeData.__config__.jnpfKey === "roleSelect"
            ? _c("role-select", {
                key: _vm.key,
                attrs: {
                  placeholder: "选择默认值",
                  clearable: "",
                  multiple: _vm.activeData.multiple,
                },
                model: {
                  value: _vm.activeData.__config__.defaultValue,
                  callback: function ($$v) {
                    _vm.$set(_vm.activeData.__config__, "defaultValue", $$v)
                  },
                  expression: "activeData.__config__.defaultValue",
                },
              })
            : _vm._e(),
          _vm.activeData.__config__.jnpfKey === "editor"
            ? _c("el-input", {
                attrs: {
                  value: _vm.setDefaultValue(
                    _vm.activeData.__config__.defaultValue
                  ),
                  placeholder: "请输入默认值",
                },
                on: { input: _vm.onDefaultValueInput },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.activeData.__config__.jnpfKey !== "editor"
        ? _c(
            "el-form-item",
            { attrs: { label: "能否清空" } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.activeData.clearable,
                  callback: function ($$v) {
                    _vm.$set(_vm.activeData, "clearable", $$v)
                  },
                  expression: "activeData.clearable",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.activeData.__config__.jnpfKey == "userSelect"
        ? _c(
            "el-form-item",
            { attrs: { label: "获取默认" } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.activeData.default,
                  callback: function ($$v) {
                    _vm.$set(_vm.activeData, "default", $$v)
                  },
                  expression: "activeData.default",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.activeData.__config__.jnpfKey !== "editor"
        ? _c(
            "el-form-item",
            { attrs: { label: "能否多选" } },
            [
              _c("el-switch", {
                on: { change: _vm.multipleChange },
                model: {
                  value: _vm.activeData.multiple,
                  callback: function ($$v) {
                    _vm.$set(_vm.activeData, "multiple", $$v)
                  },
                  expression: "activeData.multiple",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.activeData.__config__.jnpfKey !== "editor"
        ? _c(
            "el-form-item",
            { attrs: { label: "是否禁用" } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.activeData.disabled,
                  callback: function ($$v) {
                    _vm.$set(_vm.activeData, "disabled", $$v)
                  },
                  expression: "activeData.disabled",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-form-item",
        { attrs: { label: "是否必填" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData.__config__.required,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.__config__, "required", $$v)
              },
              expression: "activeData.__config__.required",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }