var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    [
      _c(
        "el-form-item",
        { attrs: { label: "显示标题" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData.__config__.showTitle,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.__config__, "showTitle", $$v)
              },
              expression: "activeData.__config__.showTitle",
            },
          }),
        ],
        1
      ),
      _vm.activeData.actionText !== undefined
        ? _c(
            "el-form-item",
            { attrs: { label: "动作文字" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入动作文字" },
                model: {
                  value: _vm.activeData.actionText,
                  callback: function ($$v) {
                    _vm.$set(_vm.activeData, "actionText", $$v)
                  },
                  expression: "activeData.actionText",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-form-item",
        { attrs: { label: "动作设置" } },
        [
          _c("el-switch", {
            attrs: { "active-value": 1, "inactive-value": 0 },
            model: {
              value: _vm.activeData.addType,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "addType", $$v)
              },
              expression: "activeData.addType",
            },
          }),
        ],
        1
      ),
      _vm.activeData.addType == 1
        ? _c(
            "el-form-item",
            { attrs: { label: "动作表单" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "100%" },
                  on: {
                    click: function ($event) {
                      return _vm.editConf()
                    },
                  },
                },
                [_vm._v("配置表单")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-form-item",
        { attrs: { label: "合计设置" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData["show-summary"],
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "show-summary", $$v)
              },
              expression: "activeData['show-summary']",
            },
          }),
        ],
        1
      ),
      _vm.activeData["show-summary"]
        ? _c(
            "el-form-item",
            { attrs: { label: "合计字段" } },
            [
              _c(
                "el-select",
                {
                  attrs: { multiple: "", placeholder: "请选择合计字段" },
                  model: {
                    value: _vm.activeData.summaryField,
                    callback: function ($$v) {
                      _vm.$set(_vm.activeData, "summaryField", $$v)
                    },
                    expression: "activeData.summaryField",
                  },
                },
                [
                  _vm._l(
                    _vm.activeData.__config__.children,
                    function (item, i) {
                      return [
                        ["comInput", "numInput", "calculate"].includes(
                          item.__config__.jnpfKey
                        )
                          ? _c("el-option", {
                              key: i,
                              attrs: {
                                label: item.__config__.label,
                                value: item.__vModel__,
                              },
                            })
                          : _vm._e(),
                      ]
                    }
                  ),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.formVisible
        ? _c("Form", { ref: "Form", on: { submit: _vm.updateConf } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }