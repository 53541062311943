var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "SNOWS-dialog SNOWS-dialog_center field-dialog",
      attrs: {
        title: "字段设置",
        "close-on-click-modal": false,
        width: "1000px",
        "lock-scroll": "",
        "append-to-body": "",
        visible: _vm.visible,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "main" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              attrs: { data: _vm.list, size: "medium" },
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", label: "序号" },
              }),
              _c("el-table-column", {
                attrs: { prop: "filedName", label: "列名" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.disabled
                          ? _c("span", { staticClass: "table-cell" }, [
                              _vm._v(_vm._s(scope.row.filedName)),
                            ])
                          : _c("el-input", {
                              attrs: {
                                placeholder: "请输入列名",
                                maxlength: "50",
                              },
                              model: {
                                value: scope.row.filedName,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "filedName", $$v)
                                },
                                expression: "scope.row.filedName",
                              },
                            }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "dataType", label: "类型" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.disabled
                          ? _c("span", { staticClass: "table-cell" }, [
                              _vm._v(
                                _vm._s(_vm._f("dataType")(scope.row.dataType))
                              ),
                            ])
                          : _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择" },
                                model: {
                                  value: scope.row.dataType,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "dataType", $$v)
                                  },
                                  expression: "scope.row.dataType",
                                },
                              },
                              _vm._l(_vm.options, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "length", label: "长度" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.disabled
                          ? _c("span", { staticClass: "table-cell" }, [
                              _vm._v(_vm._s(scope.row.length)),
                            ])
                          : _c("el-input", {
                              attrs: {
                                placeholder: "请输入长度",
                                disabled:
                                  scope.row.dataType !== "varchar" &&
                                  scope.row.dataType !== "decimal",
                              },
                              model: {
                                value: scope.row.length,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "length", $$v)
                                },
                                expression: "scope.row.length",
                              },
                            }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "isNull", label: "允许空", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.disabled
                          ? _c("el-checkbox", {
                              attrs: { value: !!scope.row.isNull },
                            })
                          : _c("el-checkbox", {
                              attrs: {
                                "true-label": true,
                                "false-label": false,
                              },
                              model: {
                                value: scope.row.isNull,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "isNull", $$v)
                                },
                                expression: "scope.row.isNull",
                              },
                            }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "isNull", label: "说明" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.disabled
                          ? _c("span", { staticClass: "table-cell" }, [
                              _vm._v(_vm._s(scope.row.comment)),
                            ])
                          : _c("el-input", {
                              attrs: { placeholder: "请输入说明" },
                              model: {
                                value: scope.row.comment,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "comment", $$v)
                                },
                                expression: "scope.row.comment",
                              },
                            }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "50" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        !scope.row.disabled
                          ? _c(
                              "el-button",
                              {
                                staticClass: "SNOWS-table-delBtn",
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDel(scope.$index)
                                  },
                                },
                              },
                              [_vm._v("删除\n          ")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "table-actions",
              on: {
                click: function ($event) {
                  return _vm.addHandle()
                },
              },
            },
            [
              _c(
                "el-button",
                { attrs: { type: "text", icon: "el-icon-plus" } },
                [_vm._v("新建字段")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.closeDialog } }, [_vm._v("取消")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.btnLoading },
              on: {
                click: function ($event) {
                  return _vm.dataFormSubmit()
                },
              },
            },
            [_vm._v("\n      确定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }