var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-drawer",
    _vm._g(
      _vm._b(
        {
          attrs: { "modal-append-to-body": false, "append-to-body": "" },
          on: { opened: _vm.onOpen, close: _vm.onClose },
        },
        "el-drawer",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "div",
        { staticClass: "action-bar" },
        [
          _c(
            "el-button",
            {
              staticClass: "copy-json-btn",
              attrs: { icon: "el-icon-document-copy", type: "text" },
            },
            [_vm._v("复制JSON")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-download", type: "text" },
              on: { click: _vm.exportJsonFile },
            },
            [_vm._v("导出JSON文件")]
          ),
          _c(
            "el-button",
            {
              staticClass: "delete-btn",
              attrs: { icon: "el-icon-circle-close", type: "text" },
              on: {
                click: function ($event) {
                  return _vm.$emit("update:visible", false)
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
      _c("JSONEditor", {
        staticClass: "json-editor",
        attrs: { mode: "json" },
        model: {
          value: _vm.template,
          callback: function ($$v) {
            _vm.template = $$v
          },
          expression: "template",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }