<template>
  <el-dialog :close-on-click-modal="false" class="SNOWS-dialog SNOWS-dialog_center form-script-dialog"
    lock-scroll append-to-body v-bind="$attrs" width="1000px" :modal-append-to-body="false"
    title="表单样式" v-on="$listeners" @open="onOpen">
    <!-- <span slot="title" class="dialog-title">
      <span>
        <el-tooltip content="小程序不支持在线JS脚本" placement="top-start">
          <a class="el-icon-warning-outline"></a>
        </el-tooltip>
      </span>
    </span> -->
    <div class="form-script-dialog-body">
      <div class="right-main">
        <div class="codeEditor">
          <JNPFCodeEditor v-model="text" :options="options" ref="CodeEditor" />
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取消</el-button>
      <el-button type="primary" @click="onClose()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import JNPFCodeEditor from '@/components/SNOWSEditor/monaco'
export default {
  components: { JNPFCodeEditor },
  props: ['value'],
  data() {
    return {
      text: '',
      options: {
        language: 'css'
      },
    }
  },
  methods: {
    onOpen() {
      this.text = this.value
      this.$nextTick(() => {
        this.$refs.CodeEditor.changeEditor({
          value: this.text,
          options: this.options
        })
      });
    },
    onClose() {
      this.$emit('updateStyleScript', this.text)
      this.closeDialog()
    },
    closeDialog() {
      this.$emit('update:visible', false)
    }
  }
}
</script>
